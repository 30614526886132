import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import './App.css';
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputBase,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Cookbook from './cookbook';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const Md = (props) => (<ReactMarkdown plugins={[gfm]}>{props.children}</ReactMarkdown>);

Cookbook.recipes.sort((lhs, rhs) => lhs.title > rhs.title);

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: '#F5FCFF',
  },
  TypographyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5FCFF',
  },
  welcome: {
    fontSize: 20,
    TypographyAlign: 'center',
    margin: 10,
  },
  instructions: {
    TypographyAlign: 'left',
    color: '#333333',
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  recipeItem: {
    TypographyAlign: 'left',
    color: 'black',
    padding: 10,
    height: 50,
  },
  ingredientsItem: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    height: 50,
  },
  ingredientIcon: {
    padding: 10,
  },
  ingredient: {
    flex: 1,
    padding: 10,
  },
  ingredientAmount: {
    padding: 10,
  },
}));

// TODO: had a regression about uppercase search (ios capitalises automatically...), add a test for this!
const recipeSearch = (recipes, keyword) =>
  recipes.filter(recipe =>
    recipe.title.toLowerCase().includes(keyword.toLowerCase()) || (recipe.ingredients || []).some((ingredient) =>
      (ingredient.name || ingredient).toLowerCase().includes(keyword.toLowerCase())));

// TODO I'd rather have the Cookbook.recipes come from props but how to do that from a react navigation thing?
// FIXME the above ^ now this is not a react navi anymore
const Recipes = ({ recipes }) => {
  const classes = useStyles();
  const [searchKeyword, setSearchKeyword] = useState("");
  const shownRecipes = recipeSearch(recipes, searchKeyword);
  const noRecipes = (
    <ListItem>
      <ListItemText className={classes.instructions}>No recipes</ListItemText>
    </ListItem>);
  const listItems = shownRecipes.length === 0 ? noRecipes : shownRecipes.map(item => (
    <ListItem key={item.title}>
      <Link to={`/${encodeURIComponent(item.title)}`}>
        <ListItemText className={classes.recipeItem}>{item.title}</ListItemText>
      </Link>
    </ListItem>
  ));
  return (
    <Container className={classes.container}>
      <InputBase
        placeholder="Filter by title or ingredient"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => setSearchKeyword(e.target.value)}
        value={searchKeyword}
      />
      <List>
        {listItems}
      </List>
    </Container>
  );
}

const Recipe = ({ recipes }) => {
  let { recipeName: recipeNameEncoded } = useParams();
  const recipeName = decodeURIComponent(recipeNameEncoded);
  const classes = useStyles();
  const recipeData = recipes.find(({ title }) => title === recipeName);
  const ingredients = (recipeData.ingredients || []).map((item, i) => (
    <ListItem key={i}>
      <ListItemIcon>
        <span role="img" aria-label="ingredient">🍳</span>
      </ListItemIcon>
      <ListItemText>
        <Md>{item.name ? `${item.amount}${item.unit} ${item.name}` : `${item}`}</Md>
      </ListItemText>
    </ListItem>
  ));
  return (
    <Container>
      <Link to="/">
        <Button>&lt; Back</Button>
      </Link>
      <Typography className={classes.welcome}>
          {recipeData.title}
      </Typography>
      <Typography className={classes.instructions}>
        <Md>{recipeData.description}</Md>
      </Typography>
      <Typography className={classes.welcome}>
        preparation
      </Typography>
      <Typography className={classes.instructions}>
        <Md>{recipeData.preparation}</Md>
      </Typography>
      <Typography className={classes.welcome}>
        notes
      </Typography>
      <Typography className={classes.instructions}>
        <Md>{recipeData.notes}</Md>
      </Typography>
      <Typography className={classes.welcome}>
        ingredients
      </Typography>
      <List>
        {ingredients}
      </List>
    </Container>
  );
}

const App = () => {
  return (
    <Container className="App" maxWidth={false} disableGutters={true}>
      <Router>
        <Routes>
          <Route path="/:recipeName" element={<Recipe recipes={Cookbook.recipes} />} />
          <Route path="/" element={<Recipes recipes={Cookbook.recipes} />} />
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
